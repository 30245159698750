import { Box, Container } from "@mui/material";
import styled from "styled-components";

export const Headline1 = styled.h1`
  color: var(--color-blue-dark);
  font-weight: 700;
  line-height: 1.95rem;
  margin-top: 2.1rem;
  font-size: 1.925rem;
  font-weight: 900;
`;

export const JumboHeadline1 = styled.h1`
  font-size: 68px;
  line-height: 0.9;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 30px;
  color: var(--color-blue-dark);

  @media only screen and (max-width: 767px) {
    font-size: 32px;
  }
`;

export const ThreeSomeContainer = styled.div`
  margin: 0;
  text-align: center;

  .MuiContainer-root {
    padding: 0;
  }

  .MuiGrid-item {
    padding: 2rem 3rem;
  }

  .MuiGrid-item.first {
    background: linear-gradient(
      45deg,
      var(--color-cyan-light) 0%,
      var(--color-cyan-medium) 75%
    );
  }
  .MuiGrid-item.second {
    background: linear-gradient(
      135deg,
      var(--color-cyan-light) 0%,
      var(--color-cyan-medium) 50%
    );
  }
  .MuiGrid-item.third {
    background: linear-gradient(
      360deg,
      var(--color-cyan-medium) 25%,
      var(--color-cyan-light) 100%
    );
  }

  .MuiGrid-item h3 {
    color: var(--color-primary);
    margin-top: 1rem;
  }

  .MuiGrid-item:nth-child(2) div {
    height: 125px;
  }

  .MuiGrid-item:nth-child(3) div {
    height: 110px;
    margin-top: 15px;
  }

  @media (max-width: 991px) {
    padding: 0 1rem;
  }
`;

export const ThreeSomeImageIcon = styled.div<{
  icon: "what" | "wrench" | "hello" | "cool";
}>`
  height: 125px;
  width: 10rem;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;

  background-image: ${({ icon }) =>
    `url("/assets/images/icons/ic-${icon}.svg")`};

  @media (max-width: 767px) {
    margin: 3rem auto 0;
  }
`;

export const BigCrossSection = styled.div`
  margin: 0;
  padding: 0;
  overflow: hidden;

  .content-container {
    margin: 0 auto;
    padding: 1rem;
    background: linear-gradient(
      180deg,
      var(--color-green-light) 0%,
      var(--color-cyan-light) 50%
    );
  }

  h1 {
    color: var(--color-primary);
  }

  h5 {
    margin: 0.5rem auto;
  }

  @media (max-width: 991px) {
    padding: 0 1rem;
  }
`;

export const ScreenshotDemoIllustration = styled(Box)`
  margin: 0;
  background-repeat: no-repeat;
  background-color: var(--color-border);
  background-position: 0 1.5rem;
  background-size: 30rem 20.25rem;
  width: 30rem;
  height: 22.125rem;
  position: absolute;
  border: 0.1rem solid var(--color-border);
  border-radius: 0.5rem;
  box-shadow: 0.15rem 0.15rem 0.75rem #00000005;
`;

export const BrowserButton1 = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  background: var(--color-orange-dark);
  border-radius: 0.6rem;
  width: 0.6rem;
  height: 0.6rem;
`;

export const BrowserButton2 = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 1.5rem;
  background: var(--color-orange-light);
  border-radius: 0.6rem;
  width: 0.6rem;
  height: 0.6rem;
`;

export const BrowserButton3 = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 2.5rem;
  background: var(--color-green-dark);
  border-radius: 0.6rem;
  width: 0.6rem;
  height: 0.6rem;
`;

export const BrowserUrl = styled.div`
  position: absolute;
  top: 0.4rem;
  left: 7.5rem;
  background: var(--color-border);
  border-radius: 0.25rem;
  width: 15rem;
  height: 0.8rem;
`;

export const JumboDividerHero = styled(Container)`
  margin-top: 0rem;
  padding: 0;
  color: var(--color-blue-dark);
  display: flex;
  z-index: 1;
`;

export const JumboDividerHeroTextContainer = styled.div`
  flex: 7/12;
  padding: 0;

  h3 {
    margin: 0;
  }

  & div {
    margin: 0.75rem 0;
    font-size: 1.05rem;
  }

  .MuiGrid-container {
    margin: 0;
  }

  .MuiGrid-item {
    margin: 0;
    padding: 0.5rem 2rem 1rem 2rem;
  }

  .top .left {
    background: linear-gradient(
      45deg,
      var(--color-yellow-light),
      var(--color-yellow-medium)
    );
    border-radius: 2rem 0 0 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .top .left img {
    position: absolute;
    width: 450px;
    height: 450px;
    margin-left: -5rem;
  }

  .top .right {
    background: linear-gradient(
      45deg,
      var(--color-yellow-light),
      var(--color-red-medium)
    );
    border-radius: 0 2rem 0 0;
    padding: 1rem 2rem;
  }

  .top .right h2 {
    display: inline-block;
    background: var(--color-yellow-dark);
    color: black;
    border-radius: 0.25rem;
    padding: 0 0.75rem;
    margin: 1.5rem 0 1rem 0;
    font-size: 1rem;
    text-transform: uppercase;
  }

  .bottom {
    border-radius: 0 0 2rem 2rem;
    padding-top: 0;
  }

  .bottom .content-grid .content-left {
    padding: 1rem 1rem 1rem 0;
  }

  .bottom .content-grid .content-right {
    padding: 1rem 0 1rem 1rem;
  }

  .bottom .left {
    background: linear-gradient(
      90deg,
      var(--color-red-light),
      var(--color-yellow-medium)
    );
    border-radius: 0;
    padding: 2.5rem 2.5rem 1rem 2.5rem;
  }

  .bottom .right {
    background: linear-gradient(
      45deg,
      var(--color-yellow-light),
      var(--color-yellow-medium)
    );
    border-radius: 0;
    padding: 2.5rem 2.5rem 1rem 2.5rem;
  }

  @media (max-width: 991px) {
    padding: 0.5rem 1rem 0 1rem;

    .top .left {
      display: none;
    }

    .top .right {
      border-radius: 2rem 2rem 0 0;
    }
  }
`;

export const PageDividerHero = styled(Container)`
  background: linear-gradient(
    -45deg,
    var(--color-blue-light),
    var(--color-blue-medium)
  );
  padding: 1.75rem;
  z-index: 1;
  color: white;
  display: flex;

  @media (max-width: 991px) {
    width: calc(100% - 2rem);
    margin: 0 1rem;
  }
`;

export const PageDividerHeroTextContainer = styled.div`
  padding: 0;
`;

export const NarrowSectionContainer = styled.div`
  text-align: center;
  max-width: 70%;
  margin: 0 auto;

  @media (max-width: 767px) {
    max-width: 95%;
  }
`;

export const JumboContainer = styled.div`
  padding: 1rem 0;
  background: linear-gradient(
    -45deg,
    var(--color-orange-medium),
    var(--color-yellow-light),
    var(--color-orange-medium)
  );
  background-size: 400% 400%;
  animation: jumbogradient 35s ease infinite;
  animation-direction: alternate;
  font-size: 1.2rem;
  border-radius: 0 0 50% 50%;

  @keyframes jumbogradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @media (max-width: 767px) {
    background: linear-gradient(
      -45deg,
      var(--color-orange-medium),
      var(--color-yellow-light),
      var(--color-orange-medium)
    );
    background-size: 600% 400%;
    animation: jumbogradient 35s ease infinite;
  }

  @media (min-width: 767px) and (max-width: 1200px) {
    background: linear-gradient(
      -45deg,
      var(--color-orange-medium),
      var(--color-yellow-light),
      var(--color-orange-medium)
    );
    background-size: 600% 400%;
    animation: jumbogradient 35s ease infinite;
  }
`;

export const JumboContent = styled.div`
  padding: 3rem 0;
`;

export const JumboBannerImage = styled.div`
  margin: 1rem 1rem -1.55rem 0;
  transform: scaleX(-1) scale(1.15);

  @media (min-width: 767px) and (max-width: 1200px) {
    margin: 5rem 0 -1.55rem 0;
  }

  @media (max-width: 767px) {
    margin: 0 0 -1.55rem 1.5rem;
  }
`;

export const PricingContent = styled.div`
  background: linear-gradient(
    -45deg,
    var(--color-orange-medium),
    var(--color-yellow-light),
    var(--color-orange-medium)
  );
  border-radius: 0 0 2rem 2rem;
  max-width: 1140px;
  margin: auto;
  padding: 1rem;

  h1 {
    font-size: 2.5rem;
  }

  .MuiCard-root {
    background: var(--color-shadow-invert-dark);
    border: none;
  }

  .MuiCardHeader-root {
    background: var(--color-primary);
  }
  .MuiTypography-h5 {
    color: white;
  }

  .MuiStack-root {
    margin-bottom: 0;
  }
  .MuiCardContent-root {
    padding: 0;
  }
  .MuiCardContent-root .MuiBox-root {
    padding: 0;
  }
  .MuiCardContent-root .MuiBox-root ul {
    margin-bottom: 0;
  }
  .MuiCardContent-root .MuiBox-root ul li {
    padding-bottom: 0.5rem;
  }
  .MuiCardContent-root .MuiBox-root ul li:nth-last-child(1) {
    padding-bottom: 0;
  }
  .MuiCardContent-root .MuiBox-root h2 {
    font-size: 1.6rem;
    font-weight: 800;
    color: var(--color-primary);
    background: var(--color-shadow-invert-dark);
    padding: 1rem 0;
    margin-bottom: 0.5rem;
  }
  .MuiCardContent-root .MuiBox-root h2 span {
    font-weight: 800;
    color: var(--color-primary);
  }
  .MuiCardContent-root .MuiBox-root p {
    padding: 0.5rem 1.5rem 1rem 1.5rem;
    border-bottom: 1px solid var(--color-shadow-blue-light);
    font-weight: 500;
  }
  .MuiCardContent-root .MuiBox-root p.list {
    padding: 0.5rem 1.5rem 1rem 3rem;
    text-indent: -1.5rem;
  }
  .MuiCardContent-root .MuiBox-root span.subtitle {
    color: var(--color-primary);
    font-size: 1rem;
    font-weight: 800;
  }
  .MuiCardContent-root .MuiBox-root span.arrow {
    color: var(--color-primary);
    padding-right: 0.5rem;
  }

  @media (max-width: 991px) {
    width: calc(100% - 2rem);
    margin: 0 1rem;
  }
`;
