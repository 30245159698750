import styled from "styled-components";

export const FooterLogo = styled.a`
  padding: 1rem;
  width: 10rem;
  background: var(--color-primary);
  border-radius: 3rem;
  text-align: center;
  margin-left: -1rem;

  & img {
    opacity: 0.85;
    width: 4.5rem;
    margin: 0 auto;
  }

  & img:hover {
    opacity: 1;
  }
`;

export const FooterContainerWrapper = styled.div`
  margin: 0;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const FooterContent = styled.div`
  padding: 2.5rem 1rem 8rem 1rem;
  width: 100%;

  .MuiGrid-item {
    padding-left: 1rem;
  }

  .MuiGrid-item a {
    text-decoration: underline;
  }

  .MuiGrid-item:nth-child(1) div {
    margin: 0.5rem 0;
  }

  .MuiGrid-item:nth-child(1) p {
    font-size: 0.65rem;
    opacity: 0.5;
    margin-top: 1rem;
  }

  .MuiGrid-item:not(:nth-child(1)) {
    border-left: 1px solid var(--color-shadow-invert-light);
  }

  .MuiGrid-item:not(:nth-child(1)) strong {
    display: inline-block;
    color: white;
    margin-bottom: 1rem;
  }

  .MuiBox-root {
    line-height: 2rem;
  }

  small {
    opacity: 0.5;
  }
`;

export const FooterMain = styled.div`
  margin-top: 3.25rem;
  padding-top: 0.5rem;
  color: var(--color-text-dark);
  background: var(--color-blue-dark);

  & a {
    color: var(--color-text-dark);
  }
  & a:hover {
    color: white;
  }

  & h3 {
    color: var(--color-blue-dark);
    font-size: 1.25rem;
    margin: 0 0 0.95rem -0.2rem;
    padding: 0;
  }
`;

export const FooterClientLogosWrapper = styled.div`
  background-image: url("/assets/images/footer-bg.png");
  background-size: cover;
  padding: 0.5rem 2.5rem 1rem 2.5rem;
  margin: 0.5rem 1rem;
  border-radius: 20px;

  h2 {
    text-align: center;
  }
`;

export const ClientLogos = styled.div`
  & img {
    width: 100%;
    padding: 1rem 2rem;
  }
`;
