import styled from "styled-components";

export const ConsentContainer = styled.div`
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  right: 10px;
  border-radius: 20px;
  box-shadow: 0.2rem 0.2rem 1rem var(--color-shadow);
  background-color: var(--color-blue-dark);
  color: white;
  font-size: 0.9rem;
  line-height: 0.9rem;
  text-align: center;
  z-index: 9999999;
  overflow: hidden;

  strong {
    margin-right: 8px;
    font-size: 1rem;
  }
`;

export const ConsentContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  div {
    width: 80%;
    padding: 1rem;
    line-height: 1.35rem;
  }
`;

export const ConsentWrapper = styled.div`
  text-align: left;
`;

export const ConsentHeader = styled.h1`
  color: white;
  font-weight: bold;
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
`;

export const ConsentButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: bold;
  background: var(--color-blue-medium);
  color: white !important;
  padding: 0.25rem;
  width: 20%;

  &:hover {
    background: var(--color-primary);
    transition: 0.25s ease;
  }
`;
