import styled from "styled-components";

type PushableButtonProps = {
  disabled?: boolean;
  size?: "md" | "sm";
  variant?: "primary" | "secondary";
};

export const PushableButton = styled.button<PushableButtonProps>`
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  text-transform: uppercase;
  font-weight: 800;
  cursor: pointer;
  outline-offset: 0.025rem;
  transition: filter 250ms;

  opacity: ${(props) => (props.disabled ? 0.55 : 1)};

  &:hover {
    filter: brightness(110%);
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

export const ShadowContainer = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.95rem;
  background: var(--color-shadow-light);
  will-change: transform;
  transform: translateY(0.125rem);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
`;

export const EdgeContainer = styled.span<PushableButtonProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.95rem;
  background: ${(props) =>
    props.variant === "primary"
      ? "linear-gradient(to left, var(--color-blue-medium) 0%, var( --color-blue-medium) 8%, var( --color-blue-medium) 92%, var(--color-blue-medium) 100%)"
      : "linear-gradient(to left, var(--color-primary) 0%, var(--color-primary) 8%, var(--color-primary) 92%, var(--color-primary) 100% )"};
`;

export const FrontContainer = styled.span<PushableButtonProps>`
  display: block;
  position: relative;
  padding: ${(props) =>
    props.size === "sm" ? "0.45rem 1.5rem" : "0.85rem 2.05rem"};
  border-radius: 2.05rem;
  font-size: 1.15rem;
  color: ${(props) =>
    props.variant === "primary" ? "white" : "var(--color-primary)"};
  background: ${(props) =>
    props.variant === "primary" ? "var(--color-primary)" : "white"};
  border: ${(props) =>
    props.variant === "primary" ? "none" : "1px solid var(--color-primary)"};
  font-weight: bold;

  will-change: transform;
  transform: translateY(-0.25rem);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);

  & > span {
    margin-top: -0.125rem;
    margin-bottom: 0.125rem;
    margin-right: 0.75rem;
  }

  &:hover {
    transform: ${(props) =>
      props.disabled ? "auto" : "translateY(-0.375rem)"};
    transition: ${(props) =>
      props.disabled
        ? "auto"
        : "transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5)"};
  }
  &:active {
    transform: ${(props) =>
      props.disabled ? "auto" : "translateY(-0.125rem)"};
    transition: ${(props) => (props.disabled ? "auto" : "transform 34ms")};
  }
`;
