import { Card, Typography } from "@mui/material";
import styled from "styled-components";

export const SpeechBubble = styled(Card)`
  background-color: transparent;
  position: relative;
  color: white;
  margin-bottom: 0.5rem;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    width: 0;
    border-top: 20px solid var(--color-blue-light);
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
  }
`;

export const TestimonialWrapper = styled.div`
  padding: 0.25rem;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;

  .MuiCardContent-root:last-child {
    padding-left: 0;
    padding-bottom: 20px;
  }
`;

export const Body = styled(Typography)`
  font-size: 1rem;
  font-style: italic;
  border-radius: 1rem;
  padding: 1rem;
  background: var(--color-blue-light);
`;

export const Name = styled(Typography)`
  color: white;
  font-weight: 700;
  opacity: 0.75;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const Subtitle = styled(Typography)`
  color: white;
  font-size: 0.75rem;
`;
