import styled from "styled-components";

export const PortraitLogoContainer = styled.div`
  background: var(--color-primary);
  border-radius: 1.5rem;
  padding: 1.3rem 1.9rem 1.5rem 1.65rem;

  img {
    width: 4rem;
  }

  @media only screen and (max-width: 767px) {
    padding: 1.35rem 1.8rem;
    & IMG {
      width: 4rem;
    }
  }
`;

export const NavbarContainer = styled.ul`
  background: var(--color-blue-dark);
  border-radius: 1.95rem;
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  list-style: none;

  @media (min-width: 769px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1024px) {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  @media (min-width: 992px) {
    margin-left: 2.5rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const NavbarLink = styled.div<{ active: boolean }>`
  margin: 0;
  padding: 0;

  color: var(--color-background);
  font-size: 1.15rem;
  font-weight: 700;
  padding: 0.65rem 0 0.8rem 0;

  &:first-child {
    border-radius: 1.95rem 0 0 1.95rem;
  }
  &:last-child {
    border-radius: 0 1.95rem 1.95rem 0;
  }

  background: ${({ active }) =>
    active ? "var(--color-primary)" : "transparent"};
  transition: 0.15s ease;

  &:hover {
    text-decoration: none;
    color: white;
    background: rgb(55, 69, 109);
    padding: 0.5rem 0 0.95rem 0;
    transition: 0.3s ease;
  }

  @media (max-width: 991px) {
    &:first-child {
      border-radius: 1.95rem 1.95rem 0 0;
    }

    &:last-child {
      border-radius: 0 0 1.95rem 1.95rem;
    }
  }

  & A {
    padding: 1.2rem 2.5rem;
    margin: 0;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 0.9rem;

    @media (min-width: 1024px) {
      padding-top: 2.25rem;
      padding-bottom: 2.25rem;
    }
  }
`;
