import React from "react";
import type { AppProps } from "next/app";
import Head from "next/head";
import Phrases from "@helpers/Phrases";
import { AuthProvider } from "@storage/AuthProvider";
import { ToastContainer } from "react-toastify";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@helpers/queries/queryClient";
import Config from "@helpers/Config";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "react-toastify/dist/ReactToastify.css";
import "../../public/assets/css/style.css";
import { ThemeProvider } from "@mui/material";
import { theme } from "@helpers/style/theme";
import { FullHeightContainer } from "@helpers/style/global.styles";

import * as Sentry from "@sentry/react";
import { ApiProvider } from "@storage/ApiProvider";
import { ApiApi } from "@api";

if (Config.SENTRY_INIT !== false) {
  Sentry.init({
    dsn: "https://09789f11b13f74c739c12a1f4f32207a@o4506205723295744.ingest.sentry.io/4506205725261824",
    debug: false,
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 0,
    replaysSessionSampleRate: 0,
  });
}

const api = new ApiApi();

const MyApp: React.FC<AppProps> = ({
  Component,
  pageProps,
  router,
}: AppProps) => {
  return (
    <ThemeProvider theme={theme}>
      <ApiProvider api={api}>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            {/* <ReactQueryDevtools /> */}
            <FullHeightContainer>
              <Head>
                <meta
                  httpEquiv="Content-Type"
                  content="text/html; charset=UTF-8"
                />
                <meta name="viewport" content="width=device-width" />
                <title>{Phrases.HTML_TITLE}</title>
                <link rel="icon" href="/favicon.png" />

                <style>{`
                *,
                *::before,
                *::after {
                  box-sizing: border-box;
                }
                html {
                  font-family: sans-serif;
                  line-height: 1.15;
                  -webkit-text-size-adjust: 100%;
                  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                  text-rendering: optimizeLegibility;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                }
                body {
                  color: var(--color-background);
                  font-size: 1rem;
                  line-height: 1.5;
                  margin: 0;
                  padding: 0;
                  min-height: 100vh;
                  width: 100%;
                  /* Changing this to clip from hidden because it breaks position sticky in other places */
                  overflow-x: clip;
                }
              `}</style>

                <meta
                  name="twitter:card"
                  content="summary_large_image"
                  key="twcard"
                />
                <meta
                  name="twitter:creator"
                  content="theTestTheTest"
                  key="twhandle"
                />

                <meta
                  property="og:url"
                  content="https://www.testthetest.com/"
                  key="ogurl"
                />
                <meta property="og:type" content="website" key="ogtype" />
                <meta
                  property="og:image"
                  content="https://www.testthetest.com/assets/images/testthetest-social.jpg"
                  key="ogimage"
                />
                <meta
                  property="og:site_name"
                  content="TestTheTest"
                  key="ogsitename"
                />
                <meta
                  property="og:title"
                  content={Phrases.HTML_TITLE}
                  key="ogtitle"
                />
                <meta
                  property="og:description"
                  content="Weekly bug reports so you can ship high quality apps, every time. No integration, no code access, no onboarding."
                  key="ogdesc"
                />
                <meta
                  name="description"
                  content="Weekly bug reports so you can ship high quality apps, every time. No integration, no code access, no onboarding."
                  key="desc"
                />
              </Head>

              <ToastContainer
                hideProgressBar
                closeButton={false}
                limit={1}
                position="top-center"
              />

              <Component {...pageProps} key={router.route} />
            </FullHeightContainer>
          </QueryClientProvider>
        </AuthProvider>
      </ApiProvider>
    </ThemeProvider>
  );
};

export default MyApp;
