import React, { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import {
  consentWasAlreadyGiven,
  gaTrackPageview,
  setConsentCookie,
  shouldAskForConsent,
} from "@helpers/AnalyticsHelper";
import {
  ConsentContainer,
  ConsentContent,
  ConsentButton,
} from "./AnalyticsScripts.styles";

const AnalyticsScripts: React.FC = () => {
  const router = useRouter();

  const [isConsentInfoLoading, setIsConsentInfoLoading] = useState(true);
  const [isConsentGiven, setIsConsentGiven] = useState(false);

  useEffect(() => {
    setIsConsentInfoLoading(false);
    setIsConsentGiven(consentWasAlreadyGiven());

    if (!shouldAskForConsent() || consentWasAlreadyGiven()) {
      gaTrackPageview();
    }
  }, []);

  const giveConsent = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setConsentCookie();
    setIsConsentGiven(true);
    router.refresh();
  };

  if (isConsentInfoLoading) {
    return null;
  }

  if (shouldAskForConsent() && !isConsentGiven) {
    return (
      <ConsentContainer>
        <ConsentContent>
          <div>
            <strong>👋 Hello Europe, Hello GDPR</strong>
            <br />
            This website wants to use cookies and add a 3rd-party Chat feature.
            It&#039;s all disabled now, but you can enable them here:
          </div>
          <ConsentButton href="#" onClick={giveConsent}>
            Continue
          </ConsentButton>
        </ConsentContent>
      </ConsentContainer>
    );
  }

  return null;
};

export default AnalyticsScripts;
